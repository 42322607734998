<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="loading"
            >

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else-if="counter"
                class="wrapperFormModal"
            >
                <v-card 
                    flat
                >
                    <v-card-text>
                        
                        <!--поле Наименование-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t("Наименование")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="counter.Name"
                                    @input="update({ property: 'Name', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!--поле Индекс-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t("Индекс")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="counter.Index"
                                    @input="update({ property: 'Index', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!--поле Значение счетчика-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t("Значение_счетчика")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <!-- <div v-if="!counter.is_new_record" class="onlyReadData">
                                    {{ counter.Value ? counter.Value : $t("Не_указано") }}
                                </div>
                                <v-text-field
                                    v-else
                                    :value="counter.Value"
                                    @input="update({ property: 'Value', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    type="number"
                                    :rules="counterValueRules"
                                >
                                </v-text-field> -->

                                <v-text-field
                                    :value="counter.Value"
                                    @input="update({ property: 'Value', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    type="number"
                                    :rules="counterValueRules"
                                >
                                </v-text-field>
                                
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card
                    flat
                >
                    <v-card-text>
                        Произошла ошибка загрузки счетчика
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="!loading || counter"
                >
                    {{ $t("Сохранить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "OrderCounterDlg",
    data () {
        return {
            title: "Счетчик",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            counterValueRules: [
                v => !!v || "Значение обязательно!",
                v => ( v && v >= 0 ) || "Значение счетчика не может быть отрицательным",
                v => ( v && v <= 2147483647 ) || "Значение счетчика превышает максимально допустимое",
            ],
            years: [...Array(20)].map((a,b)=> new Date().getFullYear() - b),
        }
    },
    computed: {
        ...mapGetters('dialogs/orderCounter', { counter: 'getCounter', visible: 'isVisible', loading: 'isLoading' }),
    },
    methods: {
        ...mapActions('dialogs/orderCounter', { ok: 'ok', cancel: 'cancel', update: 'updateDataSource' }),
    }
}
</script>